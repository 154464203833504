html {
  padding: 0;
  margin:0;
  display: inherit;
  background: linear-gradient(180deg, #92B0FF 0%, #50DF2D 157.93%) no-repeat;
  min-height: 100vh;    
  text-align: center;
  font-family: sans-serif!important;
}

.contenedor{
  position: absolute;
  width: 100vw;
  height: 100vh;
  left: 0px;
  bottom: 0px;
  overflow: hidden;  
}

.uno{
position: absolute;
width: 20.10vw;
height: 32.89vw;
left: -5.70vw;
bottom: 0vw;
background: #50DF2D;
border-radius: 13.02vw 13.02vw 0 0;
}

.dos{
  position: absolute;
width: 12.77vw;
height: 25.11vw;
left: 11.97vw;
bottom: 0vw;

background: #26B402;
border-radius: 13.02vw 13.02vw 0 0;
}

.tres{
  position: absolute;
width: 9.01vw;
height: 20vw;
left: 22.27vw;
bottom: 0vw;

background: #218F05;
border-radius: 13.02vw 13.02vw 0 0;
}

.cuatro{
  position: absolute;
width: 20.10vw;
height: 32.89vw;
left: 72.31vw;
bottom: 0vw;

background: #50DF2D;
border-radius: 13.02vw 13.02vw 0 0;
}

.cinco{
  position: absolute;
width: 9.01vw;
height: 20vw;
left: 66.99vw;
bottom: 0vw;

background: #218F05;
border-radius: 13.02vw 13.02vw 0 0;
}

.seis{
  position: absolute;
width: 14.53vw;
height: 25.11vw;
left: 88.30vw;
bottom: 0vw;

background: #26B402;
border-radius: 13.02vw 13.02vw 0 0;
}

.cohete{
  position: relative;
  width: 40vw;
  height: 40vw;
  margin: 0 auto;
  top: 0vh;
  animation: ease-in;
  transition: 1.5s;
}

.logo{
  position: absolute;
  height: auto;
  width: 100%;
  max-width: 300px;
  left: 2vw;
  top: 2vh;
}

.texto{
position: relative;
margin: 0 auto;
width: 24.93vw;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 1.95vw;
margin-top: -10vh;
/* or 30px */
color: #111111;
}

.mail{
  position: relative;
  margin: 0 auto;
  width: 24.28vw;
  margin-top: -10vh;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.95vw;


color: #0500F8;

}

.logo:hover{
  filter: drop-shadow(0px 0px 10px rgb(250, 246, 246));
}

@media screen and (max-width: 800px) {

  .contenedor{
      height: 100vh;
  }

  .mail{
      font-size: 5vw ;
      width: 55vw;
      height: 5vw;
      top: 6vh!important;
  }

  .texto{
      font-size: 5vw ;
      width: 55vw;
      height: 5vw;
      top: 0vh!important;
  }

  .cohete{
      width: 70vw;
      height: 70vw;
      top: 45vh;
      animation: ease-in 5s;
      transition: 1.5s;
  }

  .logo{
      left: 25vw;
      top: 5vh;

  }

  .uno{

  bottom: 0vw;

  }
  
  .dos{

  bottom: 0vw;

  }
  
  .tres{

  bottom: 0vw;

  }
  
  .cuatro{

  bottom: 0vw;

  }
  
  .cinco{

  bottom: 0vw;

  }
  
  .seis{

  bottom: 0vw;

  }

}